:root {
    --header-height: 75px;
    --barlow: 'Barlow Semi Condensed', sans-serif;
    --josefin: 'Josefin Sans', sans-serif;
}

* {
    outline: 0;
}

html {
    box-sizing: border-box;
    font-size: 16px;
}
*, *:before, *:after {
    box-sizing: inherit;
}

body {
    background: #F8F8F8;
    font-family: Helvetica;
    margin: 0;
}

.app {
    width: 100%;
}

.app__sidebar {
}

.app__main {
    position: fixed;
}

.input {
    background: #EEF2F5;
    border: 0;
    height: 45px;
    font-size: 1rem;
    padding: 10px 20px;
}

.label {
    color: #9AA2B1;
    font-family: var(--barlow);
    outline: 0;
}

.button {
    background: #6C7F52;
    border: 0;
    color: white;
    cursor: pointer;
    height: 45px;
    font-size: 1rem;
    font-family: var(--barlow);
    outline: 0;
    text-transform: uppercase;
}

.link-button {
    background: transparent;
    border: 0;
    cursor: pointer;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    text-transform: inherit;
    padding: 0;
}

@import './styling/table.css';
