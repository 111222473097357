.table {
    border-collapse: collapse;
}

.table__row {
    border-bottom: 2px solid #F0F2F2;
}

.table__row:hover {
    background: #F0F2F2;
}

.table__header {
    color: #A8AFB2;
    font-family: var(--barlow);
    font-weight: 600;
    padding: 10px 5px;
    text-transform: uppercase;
}

.table__data {
    color: #747C7F;
    padding: 10px;
}

.table__data:nth-child(odd) {
}
