.login {
    align-items: center;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.login__username,
.login__password {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.login__label {
    margin-bottom: 10px;
}

.login__input {
    width: 300px;
}

.login__button {
    margin-top: 20px;
    width: 300px;
}
